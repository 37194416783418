<template>
  <div>
    <div class="box box--white mt-32">
      <h3 class="headline--md text-bold">Convert Toko Setting</h3>

      <template v-if="params.length">
        <div v-for="(info, index) in $v.params.$each.$iter" :key="info.id" class="d-flex align-end mt-24 mb-24">
          <p class="text-bold" v-if="info.$model.key === 3">
            Current Fee Convert From ETH To BSC: {{ info.$model.value }}%
          </p>
          <p class="text-bold" v-if="info.$model.key === 4">
            Current Fee Convert From BSC To ETH: {{ info.$model.value }}%
          </p>

          <div
            v-if="$auth('Page', currentUser, $accessRule.ConvertTokoSettingUpdate).canView()"
            class="d-flex align-end"
          >
            <div class="form-group mr-8">
              <label class="form-label d-block">New Value</label>
              <input v-model="info.model.$model" type="text" class="form-control form-control--md" />
            </div>

            <button :disabled="info.model.$anyError" class="btn btn--md btn--primary" @click="confirmUpdate(index)">
              Submit
            </button>
          </div>
        </div>
      </template>
    </div>

    <confirm-dialog
      v-if="isModalConvertTokoUpdateOpen"
      :open.sync="isModalConvertTokoUpdateOpen"
      confirmText="Are you sure you would like to update?"
      confirmButtonText="Submit"
      confirmButtonClass="text-purple"
      @confirm="updateSettingInfo"
    />

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import toggleLoading from '@/mixins/toggle-loading'
import { deepCopy } from '@/utils/json-parser'
import { CONVERT_TOKO_SETTINGS } from '@/utils/key-mapping'
import convertTokoSettingFormValidator from '@/validations/convert-toko-setting-form'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  data() {
    return {
      isModalConvertTokoUpdateOpen: false,
      settingInfoSelected: {},
      params: []
    }
  },

  mixins: [toggleLoading, convertTokoSettingFormValidator],

  components: {
    ConfirmDialog
  },

  computed: {
    ...mapState('convertToko', ['convertTokoSettings'])
  },

  methods: {
    ...mapActions('convertToko', ['getConvertTokoSettings', 'updateConvertTokoSetting']),

    initParams() {
      if (!this.convertTokoSettings || !this.convertTokoSettings.length) return

      Object.keys(CONVERT_TOKO_SETTINGS).forEach((key, index) => {
        const settingInfo = this.convertTokoSettings.filter(info => info.key === CONVERT_TOKO_SETTINGS[key])[0]
        if (settingInfo)
          this.params[index] = deepCopy({
            ...settingInfo,
            model: ''
          })
      })
    },

    confirmUpdate(index) {
      if (!this.params[index].model) return

      this.settingInfoSelected = this.params[index]
      this.isModalConvertTokoUpdateOpen = true
    },

    updateSettingInfo() {
      this.toggleLoading()

      const req = {
        ...deepCopy(this.settingInfoSelected),
        value: this.settingInfoSelected.model
      }
      delete req.model

      this.updateConvertTokoSetting(req)
        .then(() => {
          this.settingInfoSelected.value = req.value
          this.settingInfoSelected.model = ''
          this.$v.$touch()
        })
        .finally(() => {
          this.toggleLoading()
          this.isModalConvertTokoUpdateOpen = false
        })
    }
  },

  async created() {
    await this.getConvertTokoSettings()
    this.toggleLoading()
    this.initParams()
    this.$v.$touch()
  }
}
</script>
